<template>
  <div class="index">
    <!-- 首页 -->
    <div class="page-bg" v-if="show == 'index'">
      <img src="../assets/image/bg1.png" alt="" class="con-bg-img" />

      <!-- 下载app按钮 -->
      <img  src="../assets/image/download-btn.png" alt="" class="download-btn"  @click="goDow()"/>
    </div>
    <!-- 关于我们 -->
    <div class="page-bg" v-if="show == 'about'">
      <img src="../assets/image/bg2.png" alt="" class="con-bg-img" />
    </div>
    <!-- 导航栏 -->
    <div class="nav">
      <div class="nav-content">
        <div class="nav-logo" @click="showIndex">
          <img src="../assets/image/logo.png" alt />
        </div>
        <div class="nav-tag">
          <div>
            <p @click="showIndex">首页</p>
            <div v-if="show == 'index'" class="line"></div>
          </div>
          <div>
            <p @click="showAbout">关于我们</p>
            <div v-if="show == 'about'" class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footer-content">
        <div class="footer-content-top">
          <p @click="goPage('/userAgreement')">用户协议</p>
          <div class="line"></div>
          <p @click="goPage('/PrivacyAgreement')">隐私协议</p>
        </div>
        <div class="footer-content-text">
          <p>2024©All Rights Reserved.沈阳爱就玩网络科技有限公司 版权所有</p>
          <p class="pointer" @click="goExternalLink">辽ICP备2024036708号-3 </p>
          <p>邮箱: 1191863377@qq.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "indexPage",
  data() {
    return {
      show: "index",
    };
  },

  methods: {
    // 首页
    showIndex() {
      this.show = "index";
    },
    // 关于我们
    showAbout() {
      this.show = "about";
    },
    // 跳转页面
    goPage(url) {
      this.$router.push(url);
    },
    // 链接工信部
    goExternalLink() {
      window, (location.href = "https://beian.miit.gov.cn/");
    },
	// 链接工信部
goDow(){
	window, (location.href = "https://font.aijiuwan.com/#/app_download");
	},
  },
};
</script>

<style scoped lang="scss">
.index {
  width: 100%;
  height: 100vh;
  min-width: 1920px;
  min-height: 1080px;
  background-color: #0d0e26;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-bg {
    width: 1920px;
    height: 1080px;
    position: relative;
    .con-bg-img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .download-btn {
      width: 216px;
      height: 60px;
      position: absolute;
      left: 850px;
      top: 650px;
      cursor: pointer;
    }
  }
}
.nav {
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  .nav-content {
    width: 1100px;
    display: flex;
    justify-content: space-between;
    .nav-logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 130px;
        height: 40px;
      }
    }
    .nav-tag {
      width: 160px;
      height: 100%;
      line-height: 60px;
      display: flex;
      div {
        width: 80px;
        height: 100%;
        text-align: center;
        cursor: pointer;
        position: relative;
        p {
          width: 80px;
          height: 58px;
          color: #fff;
          font-size: 14px;
        }
        .line {
          width: 12px;
          height: 2px;
          background-color: #fff;
          position: absolute;
          left: 50%;
          bottom: 14px;
          margin-left: -6px;
        }
      }
    }
  }
}
.footer {
  width: 100%;
  height: 170px;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  .footer-content {
    width: 1100px;
    text-align: center;
    padding-top: 30px;
    color: #fff;
    .footer-content-top {
      height: 20px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      font-size: 14px;
      p {
        cursor: pointer;
      }
      .line {
        width: 2px;
        height: 16px;
        background-color: #fff;
        margin: 2px 10px;
      }
    }
    .footer-content-text {
      font-size: 12px;
      p {
        margin: 3px 0;
      }
      .pointer {
        cursor: pointer;
      }
    }
  }
}
</style>
